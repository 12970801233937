<script setup lang="ts">
import { FormField, IconButton, ProductCard, Checkbox } from '@laam/ui/base';
import { vElementVisibility } from '@vueuse/components';
import { watchDebounced, useWindowScroll, watchOnce } from '@vueuse/core';
import { useRoute } from 'vue-router';
import ProductQuickAdd from '~/components/product-detail/ProductQuickAdd.vue';
import { PhBell } from '@phosphor-icons/vue';
import type { ProductSchema } from '@laam/lib/types';
import { useCurrency } from '~/stores/currency';
import { storeToRefs } from 'pinia';
import LazyImage from './LazyImage.vue';
import { useGlobalStore } from '~/stores/global';
import type { ProductsResponseSchema } from '@laam/cms-shared';
import { isMobileView } from '~/utils/helpers';
import { productTitle } from '~/utils/helpers';

const { $cathodeClient } = useNuxtApp();

interface ProductCardProps {
	data: ProductsResponseSchema['products'][number];
	dataSource: {
		dataSource: string;
		clickedFrom: string[];
		version: string | undefined;
	};
	page: string;
	isHvc: boolean;
	horizontalIndex: number;
	verticalIndex?: number;
}

const props = defineProps<ProductCardProps>();
const CurrencyStore = useCurrency();

const { currencySymbol } = storeToRefs(CurrencyStore);
const { getFormattedPrice } = CurrencyStore;

provide('currencySymbol', currencySymbol);
provide('getFormattedPrice', getFormattedPrice);

const { $eventClient } = useNuxtApp();

const computeImgAltTag = (product: any) => {
	if (product.node_l1 && product.node_l4) {
		return productTitle(product, true);
	} else return `${product.title} - ${product.partner}`;
};

function handleClickEvent() {
	$cathodeClient.queueEvent('product_card_click', {
		source: window.location.href,
		redirectUrl: `/products/${props.data.handle}`,
		clicked_from: props.dataSource.clickedFrom.join(','),
		data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
	});
	if (props.page === 'Homepage') {
		$eventClient.sendEvent('homepage-carousel-click', {
			to: props.data.handle,
			index: props.verticalIndex as number,
			title: props.data.title!,
		});
	}
}

const isVisible = ref(false);
function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

const route = useRoute();
watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('page_view', {
				data: [
					{
						id: String(props.data.shopify_id),
						inventory: props.data.total_inventory_quantity,
					},
				],
				source: window.location.href,
				page: props.page,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
				clicked_from: props.dataSource.clickedFrom.join(','),
				collection_handle: route.path.split('/').pop(),
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);

// to watch if wondow has scrolled for load vs scroll impressions
const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchOnce(y, () => {
	hasWindowScrolled.value = true;
});

const editMode = inject('editMode', ref(false));

const notifyMeProduct: Ref<{
	show: boolean;
	shopifyId: number;
	url: string;
	disabled: number[];
}> = inject('notifyMeProduct')!;
const handleNotifyMe = () => {
	notifyMeProduct.value.show = true;
	notifyMeProduct.value.shopifyId = props.data.shopify_id;
	notifyMeProduct.value.url = props.data.handle;

	$eventClient.sendEvent('notify-me', {
		meta_data: {
			product: props.data as unknown as Partial<ProductSchema>,
		},
	});
};

const globalStore = useGlobalStore();
</script>
<template>
	<NuxtLink
		class="w-full"
		:to="`/products/${data.handle}`"
		@click="handleClickEvent()"
	>
		<div v-element-visibility="[onElementVisibility, { threshold: 0.8 }]">
			<ProductCard
				:index="horizontalIndex"
				:data-source="dataSource"
				page="Homepage"
				:is-hvc="isHvc"
				:brand-name="data.brand"
				:collection-name="data.title ?? ''"
				:tags="data.tags"
				:inventory-quantity="data.total_inventory_quantity"
				:handle="data.handle"
				:shopify-id="data.shopify_id"
				:horizontal-index="horizontalIndex"
				:vertical-index="verticalIndex"
				:image-alt="data.image.alt"
				:image-url="data.image.src"
				:valhalla-score="data.valhalla_score"
				:variants="data.variants"
				:laam-store="true"
			>
				<template #image>
					<LazyImage
						:src="data.image.src"
						:alt="computeImgAltTag(data)"
						:width="isMobileView() ? 150 : 300"
					/>
				</template>

				<template #top-right>
					<div v-if="globalStore.store.store_type === 'MARKETPLACE'">
						<WishlistButton
							v-if="!editMode"
							:product-id="data.shopify_id"
							:image-url="data.image.src"
							:image-alt="data.title"
						/>

						<FormField
							v-else
							v-slot="{ value, handleChange }"
							name="selectedProducts"
							type="checkbox"
							:value="data.shopify_id"
						>
							<div
								class="right-none top-none p-md select_trigger absolute bg-transparent"
								@click.stop="(e) => e.preventDefault()"
							>
								<Checkbox
									:checked="value.includes(data.shopify_id)"
									@update:checked="handleChange"
								/>
							</div>
						</FormField>
					</div>
					<div v-else></div>
				</template>
				<template #bottom-right>
					<ProductQuickAdd
						v-if="data.total_inventory_quantity !== 0"
						:handle="data.handle"
					/>
					<IconButton
						v-else
						class="right-md bottom-md absolute"
						size="sm"
						variant="secondary"
						rounded="full"
						:disabled="notifyMeProduct?.disabled?.includes(data.shopify_id)"
						@click.prevent="handleNotifyMe"
					>
						<PhBell size="20" />
					</IconButton>
				</template>
			</ProductCard>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import { PhCircle } from '@phosphor-icons/vue';
import { IconButton } from '@laam/ui/base';
import { calculatePricing } from '@laam/lib/utils';
import ProductCardPrice from './ProductCardPrice.vue';
import DiscountBadge from './DiscountBadge.vue';
import EidDeliveryBadge from '../EidDeliveryBadge.vue';
import OutOfStockBadge from './OutOfStockBadge.vue';

import ShippingDaysBadge from './ShippingDaysBadge.vue';
import type { ProductSchema } from '@laam/cms-shared';
import { computed, inject, type Ref } from 'vue';
import ExpressBadge from '../ExpressBadge.vue';
import { Nov11SaleIcon } from '../../../icons';
interface ProductCardProps {
	imageUrl: string;
	imageAlt: string;
	brandName: string;
	collectionName: string;
	handle: string;
	variants: ProductSchema['variants'];
	tags: ProductSchema['tags'];
	inventoryQuantity: number;
	shopifyId: number;
	valhallaScore?: number;
	dataSource?: {
		dataSource: string;
		clickedFrom: string[];
		version: string | undefined;
	};
	page: string;
	isHvc: boolean;
	laamStore?: boolean;
}

const props = defineProps<ProductCardProps>();

const shippingDaysBadgeExp = inject('shippingDaysBadgeExp') as Ref<boolean>;

const tagsLowerCase = computed(() =>
	props.tags.map((tag) => tag.toLowerCase()),
);

const pricing = computed(() => {
	return calculatePricing(props.variants, props.isHvc);
});

const showPrice = computed(() => {
	return (
		!tagsLowerCase.value.find((tag) => tag.includes('hide_price')) &&
		pricing.value?.originalPrice !== 0
	);
});

const showNov11Sale = inject('showNov11Sale') as boolean;
</script>
<template>
	<div class="flex flex-col product_card w-full" data-testid="product-card">
		<div class="image-box aspect-[5/7] w-full rounded">
			<slot name="image"> Image</slot>
			<div
				v-if="!!pricing?.discountPercentage"
				class="left-none top-none p-md absolute bg-transparent"
			>
				<DiscountBadge
					:discount-percentage="pricing?.discountPercentage?.toString()"
				/>
			</div>

			<div class="right-none top-none p-md absolute bg-transparent">
				<slot name="top-right"> TR </slot>
			</div>
			<div class="right-none p-md bottom-none absolute bg-transparent">
				<slot name="bottom-right"> </slot>
			</div>
		</div>
		<div class="grow py-md gap-xs flex flex-col">
			<!-- Pricing Section -->
			<div v-if="showPrice" class="flex items-start justify-between">
				<ProductCardPrice
					:hvc-price="pricing!.hvcPrice"
					:original-price="pricing!.originalPrice.toString()"
					:discounted-price="
						pricing?.discountedPrice ? pricing.discountedPrice.toString() : null
					"
					:compare-at-price="pricing?.compareAtPrice.toString()!"
					:laam-store="laamStore"
				/>
				<IconButton
					size="xs"
					variant="icon"
					class="p-xxs"
					@click="
						(e) => {
							e.preventDefault();
							// navigateTo('/search');
						}
					"
				>
				</IconButton>
			</div>
			<!-- Brand - Collection Info -->
			<div class="gap-xs flex-nowrap flex items-center truncate">
				<p class="text-xs text-gray-800">{{ brandName }}</p>

				<PhCircle size="3" weight="fill" class="flex shrink-0"></PhCircle>
				<p class="text-xs text-gray-600 capitalize truncate">
					{{ collectionName }}
				</p>
			</div>
			<!-- Badges -->
			<div v-if="showPrice" class="gap-xs flex items-center">
				<Nov11SaleIcon v-if="showNov11Sale" />
				<ExpressBadge
					v-if="
						tagsLowerCase.includes('deliverydays_readytoship_rdlrg') &&
						inventoryQuantity !== 0
					"
				/>

				<ShippingDaysBadge
					v-if="
						(shippingDaysBadgeExp ?? true) &&
						!tagsLowerCase.includes('deliverydays_readytoship_rdlrg') &&
						tagsLowerCase.some((tag) => /deliverytime_\d+_rdlrg/.test(tag)) &&
						inventoryQuantity !== 0
					"
					:shipping-days="
						tagsLowerCase
							.find((tag) => /deliverytime_\d+_rdlrg/.test(tag))!
							.split('_')[1]!
					"
				/>
				<EidDeliveryBadge
					v-if="
						inventoryQuantity !== 0 &&
						tagsLowerCase.includes('deliver_eid delivery_rdlrg')
					"
				/>

				<OutOfStockBadge v-if="inventoryQuantity === 0" />
			</div>
		</div>
	</div>
</template>
